import React from "react";
import { Link } from "react-router-dom";
import image2 from "../Images/logo.png";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();

  const logoutHandler = () => {
    sessionStorage.clear();
    navigate("/pin-Admirer");
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand" to={'/'}>
            <div>
              {" "}
              <center>
                <img src={image2} alt="Pinpedrop" height="40" />
              </center>
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            <form className="d-flex" role="search">
              <button
                className="btn btn-outline-success"
                type="submit"
                onClick={() => logoutHandler()}
              >
                Logout{" "}
                <i className="fa-solid fa-arrow-right-from-bracket fa-lg"></i>
              </button>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}
