import { createSlice } from "@reduxjs/toolkit";

export const GroceryImageSlice = createSlice({
    name: 'groceryImage',
    initialState: {
        groceryImages: [],
        selectedQtyId: ''
    },
    reducers: {
        setGroceryImages: (state, action) => {
            state.groceryImages = action.payload;
        },
        setQuantityId: (state, action) => {
            state.selectedQtyId = action.payload;
        }
    }
});

export const { setGroceryImages, setQuantityId } = GroceryImageSlice.actions;

export default GroceryImageSlice.reducer;
