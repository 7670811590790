import React, { useState } from "react";
import "./Login.css";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Spinner,
} from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Api from "../api";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const login = () => {
    if (!email || !password) {
      alert("Please enter email and password");
      return;
    }

    if(!validator()){
      return;
    }

    setLoader(true);
    const data = {
      email: email,
      password: password,
      password_confirmation: password,
    };

    Api.post("login", data)
      .then(function (response) {
        setLoader(false);
        const { token } = response.data;
        sessionStorage.setItem("__auth_token", token);
        navigate("/");
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
        alert("Sorry! Please enter valid email and password");
      });
  };

  const validator = () => {
    const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!emailReg.test(email)) {
      alert("Please enter valid email");
      return;
    }
    return true;
  }

  return (
    <Container className="login-container" fluid>
      <Row className="justify-content-md-center login-row">
        <Col className="col-md-6 offset-md-3">
          <Card className="shadow login-box">
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </Form.Group>
                <Row className="justify-content-md-center ">
                  <Col className="col-md-6 offset-md-3" sm={12}>
                    <Button
                      variant="primary"
                      className="text-center login-btn"
                      type="button"
                      onClick={() => login()}
                    >
                      Login
                      {loader && <Spinner animation="grow" size="sm" />}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
