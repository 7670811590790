import { configureStore } from "@reduxjs/toolkit";

import InventoryReducer from "./reducers/inventory";
import MobileImageReducer from "./reducers/mobileImage";
import GroceryInventoryReducer from "./reducers/groceryInventory";
import GroceryImageReducer from "./reducers/groceryImage";
import GroceryCategoryReducer from "./reducers/groceryCategory";
import TabReducer from "./reducers/tab";

const AppStore = configureStore({
    reducer: {
        inventory: InventoryReducer,
        mobileImage: MobileImageReducer,
        groceryInventory: GroceryInventoryReducer,
        groceryImage: GroceryImageReducer,
        groceryCategory: GroceryCategoryReducer,
        tabManager: TabReducer
    }
});

export default AppStore;
