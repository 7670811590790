import React from "react";
import DefaultImage from "../../Images/default.jpg";
import Carousel from "react-bootstrap/Carousel";
import { useSelector } from "react-redux";

export default function ImageSlider() {
  const imageList = useSelector((state) => state.mobileImage.mobileImages);
  return (
    <div className="slider my-3">
      {imageList.length === 0 && (
        <img src={DefaultImage} alt="noimage" height="500" width="350" />
      )}

      {imageList.length > 0 && (
        <Carousel>
          {imageList.map((imageItem) => (
            <Carousel.Item key={imageItem.id}>
               <img
                  className="d-block w-100"
                  src={imageItem.image_url}
                  alt={imageItem.color.mobile_color}
                />
              <Carousel.Caption>
                <h6>Color: {imageItem.color.mobile_color}</h6>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
}
