import { createSlice } from "@reduxjs/toolkit";

export const InventorySlice = createSlice({
    name: 'inventory',
    initialState: {
        searchCategory: '',
        brands: [],
        brandType: '',
        inventoryList: [],
        viewDetails: ''
    },
    reducers: {
        setSearchCategory: (state, action) => {
            state.searchCategory = action.payload;
        },
        setBrands: (state, action) => {
            state.brands = action.payload
        },
        setBrandType: (state, action) => {
            state.brandType = action.payload
        },
        setInventory: (state, action) => {
            state.inventoryList = action.payload;
        },
        setViewDetails:(state,action) =>{
            state.viewDetails = action.payload;
        }
    }
});

export const { setSearchCategory, setBrands, setBrandType, setInventory, setViewDetails } = InventorySlice.actions;

export default InventorySlice.reducer;
