import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";

export default function TableContainer(props) {
  const { specification } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    rowRender();
  }, []);

  const specificationLabels = [
    {
      key: 'mobile_brand',
      label: 'Model Brand',
      propertyType: 'obj',
      propertyToLook: 'mobile_brand'
    },
    {
      key: 'model_name',
      label: 'Model Name',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'mobile_variant',
      label: 'Model Variant',
      propertyType: 'arr',
      propertyToLook: ''
    },
    {
      key: 'mobile_color',
      label: 'Model Colour',
      propertyType: 'arr',
      propertyToLook: ''
    },
    {
      key: 'custom_os_name',
      label: 'Custom OS',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'display_type',
      label: 'Display Type',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'display_size',
      label: 'Display Size',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'display_features',
      label: 'Display Features',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'rear_camera',
      label: 'Rear Camera',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'rear_camera_features',
      label: 'Rear Camera Features',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'front_camera',
      label: 'Front Camera',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'front_camera_features',
      label: 'Front Camera Features',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'network_connectivity',
      label: 'Network Connectivity',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'wifi',
      label: 'Wi-Fi',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'nfc',
      label: 'NFC',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'ports',
      label: 'Ports',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'bluetooth',
      label: 'Bluetooth',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'product_dimensions',
      label: 'Product Dimensions',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'processor_type',
      label: 'Processor Type',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'processor_model',
      label: 'Processor Model',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'core_number',
      label: 'Core number',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'clock_speed',
      label: 'Clock Speed',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'battery_capacity',
      label: 'Battery Capacity',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'charging_type',
      label: 'Charging Type',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'battery_features',
      label: 'Battery features',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'warranty_summary',
      label: 'Warranty Summary',
      propertyType: 'str',
      propertyToLook: ''
    },
    {
      key: 'mobile_manufacturer',
      label: 'Mobile Manufacturer',
      propertyType: 'obj',
      propertyToLook: 'manufacturer_name'
    },
    {
      key: 'mobile_manufacturer',
      label: 'Manufacturer Address',
      propertyType: 'obj',
      propertyToLook: 'manufacturer_address'
    },
    {
      key: 'mobile_manufacturer',
      label: 'Packer Name',
      propertyType: 'obj',
      propertyToLook: 'packer_name'
    },
    {
      key: 'mobile_manufacturer',
      label: 'Packer Address',
      propertyType: 'obj',
      propertyToLook: 'packer_address'
    },
    {
      key: 'mobile_manufacturer',
      label: 'Importer Name',
      propertyType: 'obj',
      propertyToLook: 'importer_name'
    },
    {
      key: 'mobile_manufacturer',
      label: 'Importer Address',
      propertyType: 'obj',
      propertyToLook: 'importer_address'
    },
  ];

  const getColor = (data) => {
    return data.map(colorItem => colorItem.mobile_color);
  }

  const getVariant = (data) => {
    return data.map(variantItem => `${variantItem.ram}GB - ${variantItem.storage} ${variantItem.storage > 1 ? "GB" : "TB"}`);
  }

  const rowRender = () => {
    const data = [];
    for (let i = 0; i < specificationLabels.length; i++) {
      let row;
      if (specificationLabels[i].propertyType === 'str') {
        row = {
          label: specificationLabels[i].label,
          value: specification[specificationLabels[i].key],
        };
      }
      else if (specificationLabels[i].propertyType === 'obj') {
        row = {
          label: specificationLabels[i].label,
          value: specification[specificationLabels[i].key][specificationLabels[i].propertyToLook],
        };
      }
      else if (specificationLabels[i].key === 'mobile_color') {
        row = {
          label: specificationLabels[i].label,
          value: getColor(specification[specificationLabels[i].key]).toString()
        };
      }
      else if (specificationLabels[i].key === 'mobile_variant') {
        row = {
          label: specificationLabels[i].label,
          value: getVariant(specification[specificationLabels[i].key]).toString()
        };
      }

      data.push(row);
    }
    setData(data);
  };

  return (<>
   <h4>Specification Table</h4>
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Label</th>
          <th>Specification</th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 &&
          data.map((item, index) => (
            <tr>
              <td>{item.label}</td>
              <td>{item.value}</td>
            </tr>
          ))}
      </tbody>
    </Table>
    </>
  );
}
