import Api from "../api";
import {
  mobileImagesEndpoint,
  mobileImageDeleteEndpoint,
} from "../api/mobile/endpoints";

export const getAllImages = (colorId) => {
  const param = `/${colorId}`;
  const url = '/' + mobileImagesEndpoint + param;
  return Api.get(url).then((response) => response.data);
};

export const trashImage = (imageId) => {
  const param = `/${imageId}`;
  const url ='/' + mobileImageDeleteEndpoint + param;
  return Api.delete(url).then((response) => response.data);
};
