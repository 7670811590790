import React from "react";


export default function Topbox() {
  return (
    <>
      <div class="container px-4">
        <div class="row my-3">
        <div class="col">
            <div className="box">
              {" "}
              <center>
                <div class="p-3 border bg-light">
                  Grocery
                  <h5>
                  <i class="fa-solid fa-bag-shopping "></i>
                  </h5>
                </div>
              </center>{" "}
            </div>
          </div>
          <div class="col">
            <div className="box">
              {" "}
              <center>
                <div class="p-3 border bg-light">
                  Electronics
                  <h5>
                    <i class="fa-solid fa-plug-circle-bolt"></i> 
                  </h5>
                </div>
              </center>{" "}
            </div>
          </div>
          <div class="col">
            <div className="box">
              {" "}
              <center>
                <div class="p-3 border bg-light">
                  Medical
                  <h5>
                    <i class="fa-solid fa-notes-medical "></i>
                  </h5>
                </div>
              </center>{" "}
            </div>
          </div>
          <div class="col">
            <div className="box">
              {" "}
              <center>
                <div class="p-3 border bg-light">
                  Home
                  <h5>
                    <i class="fa-solid fa-house "></i>
                  </h5>
                </div>
              </center>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



