import { Outlet, Navigate } from "react-router";

const PrivateRoutes = () => {
    const authToken = sessionStorage.getItem('__auth_token');
    let auth = {'token': authToken}
    return(
        auth.token ? <Outlet/> : <Navigate to="/pin-Admirer"/>
    )
}

export default PrivateRoutes