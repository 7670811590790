import { mobileBrandsEndpoint, mobileByBrandsEndpoint } from "./endpoints";
import Api from "..";

export const getBrands = (category) => {
  let urlEndpoint = "";
  switch (category) {
    case "Mobile":
      urlEndpoint = mobileBrandsEndpoint;
      break;
    case "Television":
      urlEndpoint = mobileBrandsEndpoint;
      break;
    default:
      break;
  }
  if (!urlEndpoint) {
    return false;
  }
  const url = '/' + urlEndpoint;

  return Api.get(url);
};

export const getBrandItemList = (brandId, pageNo = 0) => {

  const param = `/${brandId}`;
  const url = '/' + mobileByBrandsEndpoint + param;
  return Api.get(url);
};

