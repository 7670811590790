import React from "react";
import DefaultImage from "../../Images/default.jpg";
import Carousel from "react-bootstrap/Carousel";
import { useSelector } from "react-redux";
import GroceryImageTable from "./GroceryImageTable";

export default function GroceryImageSlider({itemId}) {
  const imageList = useSelector((state) => state.groceryImage.groceryImages);

  return (
    <div className="slider my-3">
      {imageList.length === 0 && (
        <img src={DefaultImage} alt="noimage" height="500" width="350" />
      )}

      {imageList.length > 0 && (
        <Carousel>
          {imageList.map((imageItem) => (
            <Carousel.Item key={imageItem.id}>
              {imageItem.images.length> 0 && (
                <img
                  className="d-block w-100"
                  src={imageItem.images[0].image_url}
                  alt="grocery-mg"
                  style={{ height: "300px", width: "300px" }}
                />
              )}
              {
                imageItem.images.length === 0 &&
                <img src={DefaultImage} alt="noimage" height="500" width="350" />
              }

              <Carousel.Caption>
                <h6>Quantity: {imageItem.quantity}</h6>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
      <GroceryImageTable itemId={itemId} />
    </div>
  );
}
