import React, { useState, useRef } from "react";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import { useDispatch } from "react-redux";
import Api from "../../api";
import { groceryUploadEndpoint } from "../../api/mobile/endpoints";
import { getUnitDetails } from "../../api/mobile/grocery";
import { setGroceryImages } from "../../store/reducers/groceryImage";

export default function GroceryUploader({ itemId, units, itemName, brand }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [unitQty, setUnitQty] = useState("");
  const [loader, setLoader] = useState(false);
  const fileInputRef = useRef();
  const dispatch = useDispatch();

  const unitHandler = (value) => {
    setUnitQty(value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();
      const modifiedFile = new File(
        [file],
        `${itemName}_${unitQty}.${fileExtension}`,
        { type: file.type }
      );
      setSelectedFile(modifiedFile);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }
    if (!unitQty) {
      alert("Please select a unit!");
      return;
    }
    let isImageForAll = '';
    if (unitQty === 'all') {
        isImageForAll = units.map(item => item.id)
    }
    setLoader(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("brand", brand);
      formData.append("item", itemName);
      formData.append('quantityId', unitQty);
      formData.append('isImageForAll', isImageForAll)

      const url = groceryUploadEndpoint;
      const { data } = await Api.post(
        url,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoader(false);
      if (data.response === 'success') {
        setSelectedFile(null);
        fileInputRef.current.value = "";
        alert("Image uploaded successfully!");
        getUnitDetails(itemId).then(response => {
            imageDataHandler(response.data);
            return;
        });
      }
      fileInputRef.current.value = "";
      setSelectedFile(null);
  };

  const imageDataHandler = (unitData) => {
    const formattedQty = unitData.map((item) => ({
      id: item.id,
      quantity: item.item_quantity + item.grocery_unit.grocery_unit,
      images: item.grocery_images,
    }));
    dispatch(setGroceryImages(formattedQty));
  };

  return (
    <div class="container text-center">
      <div class="row">
        <div class="col">
          <div className="viewfileupload">
            <div>
              <h3 style={{ color: "#696969" }}>Image Upload</h3>
            </div>
            <Container>
              <Row>
                <Col xs={3}>
                  <label>Quantity/Unit</label>
                  <Form.Select
                    onChange={(e) => unitHandler(e.target.value)}
                    value={unitQty}
                    aria-label="Select Quantity"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {units.length > 0 &&
                      units.map((item) => (
                        <option key={item.id} value={item.id}>
                          {`${item.item_quantity}${item.grocery_unit.grocery_unit}`}
                        </option>
                      ))}
                    <option value="all">Same for all unit</option>
                  </Form.Select>
                </Col>

                <Col xs={6}>
                  <label>Choose file</label>
                  <Form.Group controlId="formFileSm" className="mb-3 px-7">
                    <Form.Control
                      type="file"
                      size="sm"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col className="pt-4">
                  {selectedFile && (
                    <CloseButton
                      as="input"
                      type="submit"
                      value="Cancel"
                      onClick={handleCancel}
                    ></CloseButton>
                  )}
                </Col>
                <Col xs={2} className="pt-4">
                  {selectedFile && (
                    <Button onClick={handleUpload}>
                      Upload
                      {loader && <Spinner animation="grow" size="sm" />}
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
