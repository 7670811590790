import React from "react";
import DataTable from "react-data-table-component";

const tableHeaderstyle = {
  headCells: {
    style: {
      fontWeight: "bold",
      fontSize: "14px",
      backgroundColor: "yellow",
    },
  },
};

export default function InventoryTable({
  columns = [],
  tableData = [],
  
}) {
  return (
    <DataTable
      customStyles={tableHeaderstyle}
      columns={columns}
      data={tableData}
      pagination
      selectableRows
      selectableRowsHighlight
      highlightOnHover
      subHeader
      subHeaderAlign="right"
      fixedHeader={true}
    />
  );
}
