import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Form, FormGroup, Col, Button, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getGroceryItemList } from "../../api/mobile/grocery";

import {
  setBrandType,
  setInventory,
} from "../../store/reducers/groceryInventory";

export default function GrocerySearchBar({brands}) {
  const [loader, setLoader] = useState(false);
  const brandType = useSelector((state) => state.groceryInventory.brandType);

  const dispatch = useDispatch();

  const getBrandItems = () => {
    if (!brands) {
      alert("Please select brand");
    }
    setLoader(true);
    getGroceryItemList(brandType)
      .then(function (response) {
        setLoader(false);
        dispatch(setInventory(response.data));
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
      });
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col md={6}>
            <Form className="search-form">
              <Row>
                {brands.length > 0 && (
                  <FormGroup as={Col} controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>
                      Brands
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Select
                        value={brandType}
                        onChange={(e) =>
                          e.target.value &&
                          dispatch(setBrandType(e.target.value))
                        }
                      >
                        <option value="">Select</option>
                        {brands.map((brandItem) => (
                          <option key={brandItem.id} value={brandItem.id}>
                            {brandItem.grocery_brand}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </FormGroup>
                )}
                {brandType && (
                  <FormGroup as={Col} controlId="formHorizontalEmail">
                    <Button
                      variant="primary"
                      disabled={!brands ? true : false}
                      onClick={getBrandItems}
                    >
                      <i class="fa-solid fa-magnifying-glass" />
                      {loader && <Spinner animation="grow" size="sm" />}
                    </Button>
                  </FormGroup>
                )}
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
