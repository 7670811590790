import axios from "axios";

const Api = axios.create({
  baseURL: process.env.REACT_APP_LOCAL_API_URL + '/api/v1/',
  headers: { "Access-Control-Allow-Origin": "*" },
  //withCredentials: true
});

// Function to set the authorization token in Axios headers
const setAuthToken = (token) => {
    if (token) {
        Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete Api.defaults.headers.common['Authorization'];
    }
  };

  // Interceptor to run before each request
  Api.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem('__auth_token');
      setAuthToken(token);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default Api;