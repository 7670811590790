import { createSlice } from "@reduxjs/toolkit";

export const TabSlice = createSlice({
    name: 'tabManager',
    initialState: {
        selectedTab: 'electronics'
    },
    reducers: {
        setTab: (state, action) => {
            state.selectedTab = action.payload;
        }
    }
});

export const { setTab } = TabSlice.actions;

export default TabSlice.reducer;
