import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Form, FormGroup, Col, Button, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../../api/mobile/mobile";
import { category } from "../../Data/data";
import { getBrandItemList } from "../../api/mobile/mobile";
import {
  setSearchCategory,
  setBrandType,
  setBrands,
  setInventory,
} from "../../store/reducers/inventory";

export default function SearchBar() {
  const [loader, setLoader] = useState(false);
  const searchType = useSelector((state) => state.inventory.searchCategory);
  const brandType = useSelector((state) => state.inventory.brandType);
  const brands = useSelector((state) => state.inventory.brands);
  const dispatch = useDispatch();

  const getData = async (type) => {
    const brandsData = await getBrands(type);
    if (brandsData) {
      dispatch(setBrands(brandsData.data));
      return;
    }
    dispatch(setBrands([]));
    alert("No brands found for selected category");
  };

  const getBrandList = (type) => {
    dispatch(setSearchCategory(type));
    getData(type);
  };

  const getBrandItems = () => {
    if (!brands) {
      alert("Please select brand");
    }
    setLoader(true);
    getBrandItemList(brandType)
      .then(function (response) {
        setLoader(false);
        dispatch(setInventory(response.data));
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
      });
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col md={6}>
            <Form className="search-form">
              <Row>
                <FormGroup as={Col} controlId="formHorizontalEmail">
                  <Form.Label column sm={2}>
                    Category
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Select
                      value={searchType}
                      onChange={(e) => getBrandList(e.target.value)}
                    >
                      {category.map((type) => (
                        <option key={Math.random() * 1} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </FormGroup>

                {brands.length > 0 && (
                  <FormGroup as={Col} controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>
                      Brands
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Select
                        value={brandType}
                        onChange={(e) =>
                          e.target.value &&
                          dispatch(setBrandType(e.target.value))
                        }
                      >
                        <option value="">Select</option>
                        {brands.map((brandItem) => (
                          <option key={brandItem.id} value={brandItem.id}>
                            {brandItem.mobile_brand}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </FormGroup>
                )}
                {brandType && (
                  <FormGroup as={Col} controlId="formHorizontalEmail">
                    <Button
                      variant="primary"
                      disabled={!brands ? true : false}
                      onClick={getBrandItems}
                    >
                      <i class="fa-solid fa-magnifying-glass" />
                      {loader && <Spinner animation="grow" size="sm" />}
                    </Button>
                  </FormGroup>
                )}
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
