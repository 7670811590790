import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../Navbar";
import { Table, Row, Col, Container, Form, Button } from "react-bootstrap";
import GroceryUploader from "./GroceryUploader";
import {
  getGrocerySubSubCategory,
  getUnitDetails,
} from "../../api/mobile/grocery";
import GroceryImageSlider from "./GrocerySlider";
import { setGroceryImages } from "../../store/reducers/groceryImage";
import { setSubSubCategory } from "../../store/reducers/groceryCategory";
import { saveSubCategoryEndpoint } from "../../api/mobile/endpoints";
import Api from "../../api";
import { getGroceryItemList } from "../../api/mobile/grocery";
import { setInventory } from "../../store/reducers/groceryInventory";


export default function GroceryDetail(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const itemList = useSelector((state) => state.groceryInventory.groceryList);
  const subSubCategories = useSelector(
    (state) => state.groceryCategory.subSubCategory
  );
  const brandType = useSelector((state) => state.groceryInventory.brandType);
  const [itemDetails, setItemDetails] = useState(false);
  const [unitDetails, setUnitDetails] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [subCategory, setSubCategory] = useState("");

  useEffect(() => {
    getSelectedItemDetails();
  }, []);

  useEffect(() => {
    getSelectedItemDetails();
  }, [itemList]);

  const getSelectedItemDetails = () => {
    const itemFound = findItem();
    if (itemFound) {
      getGrocerySubSubCategory().then((response) =>
        dispatch(setSubSubCategory(response.data))
      );
      setSubCategory(itemFound.item_sub_category.id);
      setItemDetails(itemFound);
    }
  }

  useEffect(() => {
    getUnitDetails(id).then((response) => {
      setUnitDetails(response.data);
      formatQuantity(response.data);
      imageDataHandler(response.data);
    });
  }, []);

  const formatQuantity = (unitQty) => {
    const formattedQty = unitQty.map(
      (item) => item.item_quantity + item.grocery_unit.grocery_unit
    );
    setQuantity(formattedQty.toString());
  };

  const imageDataHandler = (unitData) => {
    const formattedQty = unitData.map((item) => ({
      id: item.id,
      quantity: item.item_quantity + item.grocery_unit.grocery_unit,
      images: item.grocery_images,
    }));
    dispatch(setGroceryImages(formattedQty));
  };

  const findItem = () => {
    return itemList.find((item) => parseInt(item.id) === parseInt(id));
  };

  const categoryChangeHandler = (value) => {
    setSubCategory(value);
  };

  const saveSubCategoryHandler = async () => {
    const data = {
      id: id,
      subCategoryId: subCategory,
    };

    const url = saveSubCategoryEndpoint;
    const response = await Api.put(url + "/" + id, data);
    alert(response.data);

    //call and show updated data
    getGroceryItemList(brandType)
      .then(function (response) {
        dispatch(setInventory(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Container fluid>
      <Navbar />
      <Row className="p-4">
        <Col md={12}>
          {unitDetails.length > 0 && (
            <GroceryUploader
              itemId={id}
              units={unitDetails}
              itemName={itemDetails.item_name}
              brand={itemDetails.item_brand.grocery_brand}
            />
          )}
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={4}>
          <Form.Label>Update SubCategory</Form.Label>
          <Form.Select
            value={subCategory}
            onChange={(e) => categoryChangeHandler(e.target.value)}
          >
            <option value="" disabled>
              Select{" "}
            </option>
            {subSubCategories.length > 0 &&
              subSubCategories.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.sub_sub_category}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col md={4} style={{ paddingTop: "35px" }}>
          <Button
            variant="primary"
            size="sm"
            onClick={() => saveSubCategoryHandler()}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {itemDetails && (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Specification</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Item Name</td>
                  <td>{itemDetails.item_name}</td>
                </tr>
                <tr>
                  <td>Quantity</td>
                  <td>{quantity}</td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>{itemDetails.item_sub_category.sub_sub_category}</td>
                </tr>
                <tr>
                  <td>Item Meta Tags</td>
                  <td>{itemDetails.item_meta_tags}</td>
                </tr>
                <tr>
                  <td>Item Description</td>
                  <td>{itemDetails.item_description}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Col>
        <Col>
          <GroceryImageSlider itemId={id} />
        </Col>
      </Row>
    </Container>
  );
}
