import { createSlice } from "@reduxjs/toolkit";

export const GroceryInventorySlice = createSlice({
    name: 'groceryInventory',
    initialState: {
        brands: [],
        brandType: '',
        groceryList: [],
        viewDetails: ''
    },
    reducers: {
        setBrands: (state, action) => {
            state.brands = action.payload
        },
        setBrandType: (state, action) => {
            state.brandType = action.payload
        },
        setInventory: (state, action) => {
            state.groceryList = action.payload;
        },
        setViewDetails:(state,action) =>{
            state.viewDetails = action.payload;
        }
    }
});

export const { setBrands, setBrandType, setInventory, setViewDetails } = GroceryInventorySlice.actions;

export default GroceryInventorySlice.reducer;
