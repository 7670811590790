import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import UploadImgContainer from "../ViewDetails/UploadImgContainer";
import TableContainer from "../ViewDetails/TableContainer";
import ImageSlider from "../ViewDetails/ImageSlider";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ColorSelector from "../ViewDetails/ColorSelector";
import ColorTable from "../ViewDetails/ColorTable";
import { setMobileImages } from "../../store/reducers/mobileImage";
import { getAllImages } from "../../helpers";
import Navbar from "../Navbar";

export default function Mobileview() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const itemList = useSelector((state) => state.inventory.inventoryList);
  const [itemDetails, setItemDetails] = useState(false);
  useEffect(() => {
    const modelDetails = findItem();
    if (modelDetails) {
      getAllImages(modelDetails?.mobile_color[0].id).then((response) => {
        dispatch(setMobileImages(response));
      });
      setItemDetails(modelDetails);
    }
  }, []);

  const findItem = () => {
    return itemList.find((item) => parseInt(item.id) === parseInt(id));
  };

  return (
    <>
      <Container fluid>
        <Navbar />
        <Row className="p-4">
          <Col md={12}>

              {itemDetails && (
                <UploadImgContainer
                  colors={itemDetails.mobile_color}
                  modelName={itemDetails.model_name}
                  brand={itemDetails.mobile_brand.mobile_brand}
                />
              )}
          </Col>
          </Row>
          <Row className="p-4">
          <Col xs={7} className="mt-4">
              <Row>
                {itemDetails && <TableContainer specification={itemDetails} />}
              </Row>
            </Col>
          <Col xs={5}>

            {itemDetails && <ImageSlider />}
            {itemDetails && <ColorSelector colors={itemDetails.mobile_color} />}
            {
              itemDetails && <ColorTable />
            }
          </Col>
        </Row>
      </Container>
    </>
  );
}
