
export const category = [
    {
        value: '',
        label: 'Select'
    },
    {
        value: 'Mobile',
        label: 'Mobile'
    },
    {
        value: 'Laptop',
        label: 'Laptop'
    },
    {
        value: 'Smart Watch',
        label: 'Smart Watch'
    },
    {
        value: 'Home Appliances',
        label: 'Home Appliances'
    }  
];

export const searchTypes = [
    {
        value: '',
        label: 'Select'
    },
    {
        value: 'brand',
        label: 'Brand'
    },
    {
        value: 'products',
        label: 'Products'
    }    
];

export const tabHeaders = [
    {
        key: 'electronics',
        label: 'Electronics'
    },
    {
        key: 'grocery',
        label: 'Grocery'
    },
    {
        key: 'medical',
        label: 'Medical'
    }
];


export const electronicsColumn = [
    {
      name: "Sr.No",
      cell: (row, index) => index + 1,
      width: "80px"
    },
    {
        name: "Model Name",
        selector: (row) => row.model_name,
        width: "500px"
    },
    {
        name: "Operating System",
        selector: (row) => row.mobile_o_s.os_type,
        width: "300px"
    }
];

export const groceryColumn = [
    {
      name: "Sr.No",
      cell: (row, index) => index + 1,
      width: "80px"
    },
    {
        name: "Product Name",
        selector: (row) => row.item_name,
        width: "400px"
    },
    {
        name: "Product Brand",
        selector: (row) => row.item_brand.grocery_brand,
        width: "300px"
    },
    {
        name: "Category",
        selector: (row) => row.item_sub_category.sub_sub_category,
        width: "150px"
    }
];