import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import Mobileview from "../Dashboard/Mobileview/Mobileview";
import GroceryDetail from "../Dashboard/grocery/GroceryDetail";
import PrivateRoutes from "./PrivateRoutes";

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Dashboard />} path="/" exact />
          <Route element={<Mobileview />} path="/product-details/:id" />
          <Route element={<GroceryDetail />} path="/grocery-product-details/:id" />
        </Route>
        <Route element={<Login />} path="/pin-Admirer" />
      </Routes>
    </Router>
  );
}
