import { useState, useEffect } from "react";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import SearchBar from "./Tabcomponents/SearchBar";
import { electronicsColumn, groceryColumn } from "../Data/data";
import InventoryTable from "./Table";
import { useSelector, useDispatch } from "react-redux";
import { getGroceryBrands } from "../api/mobile/grocery";
import { setBrands } from "../store/reducers/groceryInventory";
import GrocerySearchBar from "./Tabcomponents/grocerySearch";
import { setTab } from "../store/reducers/tab";

export default function DashoardTab() {
  const [data, setData] = useState([]);
  const [search, SetSearch] = useState(" ");
  const [filter, setFilter] = useState([]);
  const [columnList, setColumnList] = useState([...electronicsColumn]);
  const [groceryColList, setGroceryColList] = useState([...groceryColumn]);
  const itemList = useSelector((state) => state.inventory.inventoryList);
  const groceryList = useSelector((state) => state.groceryInventory.groceryList);
  const key = useSelector((state) => state.tabManager.selectedTab);
  const brands = useSelector((state) => state.groceryInventory.brands);
  const dispatch = useDispatch();

  let list = [...electronicsColumn];
  const navigate = useNavigate();

  useEffect(() => {
    const viewButton = {
      cell: (row) => (
        <Button
          className="view-btn"
          onClick={() => viewGroceryDetail(row.id)}
          variant="primary"
          id={row.id}
        >
          View details
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '200px'
    };
    const editButton = {
      cell: (row) => (
        <Button
          className="view-btn"
          onClick={() => editGroceryDetail(row.id)}
          variant="primary"
          id={row.id}
        >
          Edit details
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '100px'
    };
    const colList = [...groceryColList, viewButton];
    setGroceryColList(colList);
  }, []);

  useEffect(() => {
    const viewButton = {
      cell: (row) => (
        <Button
          className="view-btn"
          onClick={() => viewDetailHandler(row.id)}
          variant="primary"
          id={row.id}
        >
          View details
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '200px'
    };
    const colList = [...columnList, viewButton];
    setColumnList(colList);
  }, []);

  const loadTab = (k) => {
    dispatch(setTab(k));
    if (k === 'grocery') {
      loadGroceryBrands();
    }
  }

  const loadGroceryBrands = async () => {
      const brandsData = await getGroceryBrands();
      if (brandsData) {
        dispatch(setBrands(brandsData.data));
        return;
      }
      dispatch(setBrands([]));
      alert("No brands found!");
  }

  useEffect(() => {
    const result = data.filter((item) => {
      return item.title.toLowerCase().match(search.toLocaleLowerCase);
    });
    setFilter(result);
  }, [search]);

  const viewDetailHandler = (itemId) => {
    navigate(`product-details/${itemId}`);
  };

  const handleDelete = (val) => {
    const newdata = data.filter((item) => item.id !== val);
    setFilter(newdata);
  };

  const editGroceryDetail = (itemId) => {
    navigate(`product-details/${itemId}`);
  };

  const viewGroceryDetail = (itemId) => {
    navigate(`grocery-product-details/${itemId}`);
  }

  return (
    <div className="container pt-5">
      <Tabs
        id="inventory-tab"
        className="mb-3"
        justify
        activeKey={key}
        onSelect={(k) => loadTab(k)}
      >
        <Tab eventKey="electronics" title="Electronics">
            <div className="tabtable">
                  <SearchBar />
                  {list.length > 0 && itemList.length > 0 && (
                    <InventoryTable columns={columnList} tableData={itemList} />
                  )}
              </div>
        </Tab>
        <Tab eventKey="grocery" title="Grocery">
            <div className="tabtable">
              {
                brands.length > 0 &&
                <GrocerySearchBar brands={brands} />
              }

                  { groceryList.length > 0 && (
                    <InventoryTable columns={groceryColList} tableData={groceryList} />
                  )}
              </div>
        </Tab>
      </Tabs>
    </div>
  );
}
