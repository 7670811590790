import { createSlice } from "@reduxjs/toolkit";

export const MobileImageSlice = createSlice({
    name: 'mobileImage',
    initialState: {
        mobileImages: [],
        selectedColorId: ''
    },
    reducers: {
        setMobileImages: (state, action) => {
            state.mobileImages = action.payload;
        },
        setColorId: (state, action) => {
            state.selectedColorId = action.payload;
        }
    }
});

export const { setMobileImages, setColorId } = MobileImageSlice.actions;

export default MobileImageSlice.reducer;
