import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Container, Row } from "react-bootstrap";
import { getAllImages } from "../../helpers";
import { setColorId, setMobileImages } from "../../store/reducers/mobileImage";

export default function ColorSelector({ colors }) {
  const [color, setColor] = useState(colors[0].mobile_color);
  const dispatch = useDispatch();

  const colorHandler = (color_id) => {
    setColor(color_id);
    dispatch(setColorId(color_id));
    getAllImages(color_id).then((response) => {
      dispatch(setMobileImages(response));
    });
    return;
  };

  useEffect(() => {
    dispatch(setColorId(colors[0].mobile_color));
  }, []);

  return (
    <Container className="my-3">
      <Row>
        <label>Color</label>
        <Form.Select
          onChange={(e) => colorHandler(e.target.value)}
          value={color}
          aria-label="Default select example"
        >
          <option value="" disabled>
            Select
          </option>
          {colors.length > 0 &&
            colors.map((item) => (
              <option key={item.id} value={item.id}>
                {item.mobile_color}
              </option>
            ))}
        </Form.Select>
      </Row>
    </Container>
  );
}
