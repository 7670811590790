import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

import Api from "../api";
import { groceryExcelEndpoint, mobileExcelEndpoint } from "../api/mobile/endpoints";
import SelectBox from "../components/commons/form/SelectBox";

export default function FileUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileCategory, setFileCategory] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    let urlEndpoint = '';
    switch (fileCategory) {
      case '1':
        urlEndpoint = groceryExcelEndpoint;
        break;
      case '2':
        urlEndpoint = mobileExcelEndpoint;
        break;
      default:
        return; // Early return if fileCategory is neither '1' nor '2'
    }
    const { data } = await Api.post(urlEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(data);
  };

  const categoryChangeHandler = (textValue, state) => {
    setFileCategory(textValue);
  };

  return (
    <center>
      <div>
        <div class="container text-center">
          <div class="row">
            <div class="col">
              <center>
                <div className="filebox">
                  <div>
                    {" "}
                    <h3>
                      File Upload{" "}
                      <h6>
                        <i class="fa-solid fa-file-arrow-up fa-lg"></i>
                      </h6>{" "}
                    </h3>{" "}
                  </div>
                  <Form.Group controlId="formFileSm" className="mb-3 px-5">
                    <Row>
                      <Col>
                        <SelectBox
                          placeholdertext=""
                          label="Category"
                          state={fileCategory}
                          data={[
                            {
                              id: 1,
                              value: "Grocery",
                            },
                            {
                              id: 2,
                              value: "Mobile",
                            },
                          ]}
                          textChangeHandler={categoryChangeHandler}
                          value={fileCategory}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="file"
                          size="sm"
                          onChange={handleFileChange}
                        />
                      </Col>
                    </Row>

                    <br />
                    <Button className="mx-2" onClick={() => handleSubmit()}>
                      Upload
                    </Button>
                    <Button
                      variant="danger"
                      className="mx-2"
                      onClick={() => handleSubmit()}
                    >
                      Cancel
                    </Button>
                  </Form.Group>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
}
