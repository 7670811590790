import { Table, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { useState } from "react";
import { getUnitDetails, trashGroceryImage } from "../../api/mobile/grocery";
import { setGroceryImages } from "../../store/reducers/groceryImage";
import DefaultImage from "../../Images/default.jpg";

function GroceryImageTable({ itemId }) {
  const [loader, setLoader] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();
  const imageList = useSelector((state) => state.groceryImage.groceryImages);

  const imageDeleteHandler = (id) => {
    setLoader(true);
    setDeleteId(id);
    trashGroceryImage(id).then((response) => {
      alert("Success! Image deleted successfully");
      getUnitDetails(itemId).then((response) => {
        dispatch(setGroceryImages(response));
      });
      setLoader(false);
    });
    setLoader(false);
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Quantity</th>
          <th>Image</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {imageList.length > 0 &&
          imageList.map((imageItem, index) => (
            <tr key={imageItem.created_date}>
              <td>{imageItem.quantity}</td>
              <td>
                {imageItem.images.length > 0 && (
                  <img
                    className="d-block w-100"
                    src={imageItem.images[0].image_url}
                    alt="grocery-mg"
                    style={{ height: "100px", width: "100px" }}
                  />
                )}
                {imageItem.images.length === 0 && (
                  <img
                    src={DefaultImage}
                    alt="noimage"
                    height="100"
                    width="100"
                  />
                )}
              </td>
              <td>
                {imageItem.images.length > 0 && (
                  <Button
                    variant="danger"
                    onClick={() => imageDeleteHandler(imageItem.images[0].id)}
                  >
                    <i className="fa-solid fa-trash" />
                    {loader &&
                      parseInt(imageItem.images[0].id) ===
                        parseInt(deleteId) && (
                        <Spinner animation="grow" size="sm" />
                      )}
                  </Button>
                )}
              </td>
            </tr>
          ))}

        {imageList.length === 0 && (
          <tr>
            <td colSpan={3}>No Data found</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default GroceryImageTable;
