import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import { mobileUploadEndpoint } from "../../api/mobile/endpoints";
import { setMobileImages } from "../../store/reducers/mobileImage";
import { getAllImages } from "../../helpers";
import Api from "../../api";

export default function UploadImgContainer({colors, modelName, brand}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [color, setColor] = useState("");
  const [loader, setLoader] = useState(false);
  const [colorData, setColorData] = useState({});
  const fileInputRef = useRef();
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      const modifiedFile = new File([file], `${modelName}_${color}.${fileExtension}`, { type: file.type });
      setSelectedFile(modifiedFile);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }
    if (!color) {
      alert("Please select a color!");
      return;
    }
      setLoader(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("brand", brand);
      formData.append("model", modelName);
      formData.append('colorId', colorData.id);
      formData.append('colorName', colorData.name);

      const url = mobileUploadEndpoint;
      const { data } = await Api.post(
        url,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoader(false);
      if (data.response === 'success') {
        setSelectedFile(null);
        fileInputRef.current.value = "";
        alert("Image uploaded successfully!");
        const data = getAllImages(colorData.id);
        dispatch(setMobileImages(data));
        return;
      }
      fileInputRef.current.value = "";
      setSelectedFile(null);
      alert("Oops! Something went wrong");
  };



  const colorHandler = (value) => {
    setColor(value);
    const colorName = colors.find(item => parseInt(item.id) === parseInt(value))
    const colorSet = {
      id: value,
      name: colorName.mobile_color
    }
    setColorData(colorSet);
  }

  return (
    <div>
      <div class="container text-center">
        <div class="row">
          <div class="col">
            <center>
              <div className="viewfileupload">
                <div>
                  <h3 style={{ color: "#696969" }}>Image Upload</h3>
                </div>

                <Container>
                  <Row>
                    <Col xs={3}>
                      <label>Color</label>
                      <Form.Select
                        onChange={(e) => colorHandler(e.target.value)}
                        value={color}
                        aria-label="Default select example"
                      >
                        <option value="" disabled>Select</option>
                        {colors.length > 0 && colors.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.mobile_color}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>

                    <Col xs={6}>
                      <label>Choose file</label>
                      <Form.Group controlId="formFileSm" className="mb-3 px-7">
                        <Form.Control
                          type="file"
                          size="sm"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pt-4">
                      {selectedFile && (
                        <CloseButton
                          as="input"
                          type="submit"
                          value="Cancel"
                          onClick={handleCancel}
                        ></CloseButton>
                      )}
                    </Col>
                    <Col xs={2} className="pt-4">
                      {
                        selectedFile &&
                        <Button onClick={handleUpload}>
                          Upload
                          {loader && <Spinner animation="grow" size="sm" />}
                          </Button>
                      }
                    </Col>
                  </Row>
                </Container>
              </div>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}
