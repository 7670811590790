import { groceryBrandsEndpoint, groceryByBrandsEndpoint, groceryImageEndpoint, groceryItemUnitEndpoint, groceryImageDeleteEndpoint, grocerySubSubCategory } from "./endpoints";
import Api from "..";

export const getGroceryBrands = (category) => {
  const url = '/' + groceryBrandsEndpoint;
  return Api.get(url);
};


export const getGroceryItemList = (brandId) => {
    const param = `/${brandId}`;
    const url = '/' + groceryByBrandsEndpoint + param;
    return Api.get(url);
};

export const getUnitDetails = (itemId) => {
  const param = `/${itemId}`;
    const url = '/' + groceryItemUnitEndpoint + param;
    return Api.get(url);
}

export const getGroceryImage = (itemId) => {
  const param = `/${itemId}`;
    const url = '/' + groceryImageEndpoint + param;
    return Api.get(url);
}

export const trashGroceryImage = (imageId) => {
  const param = `/${imageId}`;
  const url ='/' + groceryImageDeleteEndpoint + param;
  return Api.delete(url).then((response) => response.data);
};

export const getGrocerySubSubCategory = (category) => {
  const url = '/' + grocerySubSubCategory;
  return Api.get(url);
};
