export const mobileBrandsEndpoint = 'mobileBrands';
export const mobileByBrandsEndpoint = 'mobileByBrand';
export const mobileExcelEndpoint = 'importMobile';
export const groceryExcelEndpoint = 'importGrocery';
export const mobileUploadEndpoint = 'mobile-upload';
export const mobileImagesEndpoint = 'imageByColor';
export const mobileImageDeleteEndpoint = 'destroyImage';

//Grocery Endpoints
export const groceryBrandsEndpoint = 'groceryBrands';
export const groceryByBrandsEndpoint = 'groceryByBrand';
export const groceryItemUnitEndpoint = 'groceryItemUnit';
export const groceryUploadEndpoint = 'grocery-img-upload';
export const groceryImageEndpoint = 'groceryImgByItem';
export const groceryImageDeleteEndpoint = 'deleteGroceryImage';
export const grocerySubSubCategory = 'grocery-sub-subCategory';
export const saveSubCategoryEndpoint = 'save-subCategory';
