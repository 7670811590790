import { Table, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { trashImage, getAllImages } from "../../helpers";
import { useState } from "react";
import { setMobileImages } from "../../store/reducers/mobileImage";

function ColorTable() {
  const [loader, setLoader] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();
  const imageList = useSelector((state) => state.mobileImage.mobileImages);
  const selectedColorId = useSelector(
    (state) => state.mobileImage.selectedColorId
  );

  const imageDeleteHandler = (id) => {
    setLoader(true);
    setDeleteId(id);
    trashImage(id).then((response) => {
      alert("Success! Image deleted successfully");
      getAllImages(selectedColorId).then((response) => {
        dispatch(setMobileImages(response));
      });
      setLoader(false);
    });
    setLoader(false);
  };
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Image</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {imageList.length > 0 &&
          imageList.map((imageItem, index) => (
            <tr key={imageItem.created_date}>
              <td>{index + 1}</td>
              <td>
                {" "}
                <img
                  className="d-block w-100"
                  src={imageItem.image_url}
                  alt={"Quantity"}
                  style={{height: '100px', width: '100px'}}
                />
              </td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => imageDeleteHandler(imageItem.id)}
                >
                  <i className="fa-solid fa-trash" />
                  {loader && parseInt(imageItem.id) === parseInt(deleteId) && (
                    <Spinner animation="grow" size="sm" />
                  )}
                </Button>
              </td>
            </tr>
          ))}

        {imageList.length === 0 && (
          <tr>
            <td colSpan={3}>No Data found</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default ColorTable;
