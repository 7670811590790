import "./Dashboard.css";
import Navbar from "./Navbar";
import Topbox from "./Topbox";
import FileUpload from "./FileUpload";
import InventoryTab from "./Tab";

function Dashboard() {
  return (
    <>
      <Navbar />
      <Topbox />
      <FileUpload />
      <InventoryTab />
    </>
  );
}

export default Dashboard;
