import { createSlice } from "@reduxjs/toolkit";

export const GroceryCategorySlice = createSlice({
    name: 'groceryCategory',
    initialState: {
        subSubCategory: [],
    },
    reducers: {
        setSubSubCategory: (state, action) => {
            state.subSubCategory = action.payload;
        }
    }
});

export const { setSubSubCategory } = GroceryCategorySlice.actions;

export default GroceryCategorySlice.reducer;
