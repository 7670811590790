import React from "react";
import Form from "react-bootstrap/Form";

function SelectBox({ label, data, textChangeHandler, value, state }) {
  console.log(data)
  return (
    <div>
      <Form.Label>{label}</Form.Label>
      <Form.Select value={value} onChange={(e) => textChangeHandler(e.target.value, state, '')}>
        <option value="" disabled>
          Select{" "}
        </option>
        {data.length > 0 &&
          data.map((item) => (
            <option key={item.id} value={item.id}>
              {item.value}
            </option>
          ))}
      </Form.Select>
    </div>
  );
}

export default SelectBox;
